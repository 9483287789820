import { GET_ORDER } from './constants'
import { initialState } from './OrderProvider'
import { IOrderAction, IOrderState } from './types'

export default function orderReducer(
  state: IOrderState = initialState,
  action: IOrderAction,
): IOrderState {
  const payload = action.payload

  switch (action.type) {
    case GET_ORDER:
      return {
        ...state,
        order: payload,
      }
    default:
      return state
  }
}
