import { createContext, useReducer, useContext } from 'react'
import orderReducer from './OrderReducer'
import { IContextProviderProps } from '../../types/interfaces'
import { api } from '../../helpers/Api'
import { API_ORDERS_URL } from '../apiconfig'
import { IOrderState } from './types'
import {} from './constants'

export const initialState: IOrderState = {
  order: null,
  getOrder: () => {},
}

export const OrderContext = createContext<IOrderState>(initialState)
export const useOrderContext = () => useContext(OrderContext)

export const OrderProvider = (props: IContextProviderProps): JSX.Element => {
  const { children } = props
  const [state, dispatch] = useReducer(orderReducer, initialState)

  const getOrder = async (orders_id: string) => {
    console.log('getOrder', orders_id)
  }

  const value = {
    ...state,
    getOrder,
  }

  return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
}
