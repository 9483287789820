// Reducer consts
export const SET_ORDERS_LIST_FILTERS = 'SET_ORDERS_LIST_FILTERS'
export const CLEAR_ORDERS_LIST_FILTERS = 'CLEAR_ORDERS_LIST_FILTERS'
export const CLEAR_FILTER_CONTEXT = 'CLEAR_FILTER_CONTEXT'

// Default values
const defaultResultsPerPage = '50'
const defaultStartPage = 1

// Date values
const todayObj = new Date()
const today = todayObj.toISOString().split('T')[0]
const oneYearAgo = new Date(
  todayObj.getFullYear() - 1,
  todayObj.getMonth(),
  todayObj.getDate(),
)
  .toISOString()
  .split('T')[0]
const oneYearFromNow = new Date(
  todayObj.getFullYear() + 1,
  todayObj.getMonth(),
  todayObj.getDate(),
)
  .toISOString()
  .split('T')[0]

// Default filters
export const defaultOrdersListFilters = {
  date_purchased_start: oneYearAgo.toString(),
  date_purchased_end: today.toString(),
  shipping_date_start: '',
  shipping_date_end: '',
  staff_id: '',
  orders_status: '',
  search: '',
  is_ready_to_schedule: '',
  show_consumes_inventory: '',
  results_per_page: defaultResultsPerPage,
  page: defaultStartPage,
  customers_id: '',
}
